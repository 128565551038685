import React from "react";
import Carousel from "react-bootstrap/Carousel";

const About = () => {
    return (
        <>
            <div>
                <img style={{marginTop: 80, width: "100%"}} src="Images/abtmtt1.svg"/>
            </div>

            <div className="about-content my-5">
                <h1 className="md:text-4xl text-3xl text-blue-900 font-myFont py-8 font-bold">About Shrisatya Nidhi Limited</h1>
                <p>श्री सत्य निधी लिमिटेड ही भारतीय कंपनी कायदा 1956 च्या कलम 620-A अंतर्गत वर्ष 2022 मध्ये स्थापन झालेली पब्लिक लिमिटेड निधी कंपनी आहे.
                    ही एक निधी (परस्पर लाभ) कंपनी आहे जिथे फक्त सदस्य व्यवहार करू शकतात आणि सुविधांचा लाभ घेऊ शकतात.</p>
            </div>
            <div className="about-content mb-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h1 className="md:text-4xl text-3xl text-blue-900 font-myFont py-8 font-bold">Message From Our Chairman</h1>
                        <p>श्री सत्य निधी लिमिटेड ही भारतीय कंपनी कायदा 1956 च्या कलम 620-A अंतर्गत वर्ष 2022 मध्ये स्थापन झालेली पब्लिक लिमिटेड निधी कंपनी आहे.
                            ही एक निधी (परस्पर लाभ) कंपनी आहे जिथे फक्त सदस्य व्यवहार करू शकतात आणि सुविधांचा लाभ घेऊ शकतात.
                        </p>
                        <h1 className="md:text-4xl text-3xl text-blue-900 font-myFont py-8 font-bold">Dream and Goal</h1>
                        <p> सभासदांना बचत करण्याची सवय लावणे .
                            <br/>प्रत्येक कृतीत, दररोज ग्राहक.
                            <br/>एकत्र वाढणे, एकत्र यशस्वी होणे.
                            <br/>कार्यक्षम आणि पारदर्शक सेवांद्वारे सतत सुधारणा.
                            <br/>शाश्वतता कार्यक्रमांद्वारे लोकांचा विकास.
                            <br/>कृतीद्वारे विश्वास मिळवणे.
                            <br/>सर्वांना आधार आणि सहानुभूती प्रदान करणे.
                            <br/>सभासदांच्या नातेसंबंधावर विश्वास ठेवणे, नातेसंबंध निर्माण करा.
                            <br/>आनंदी ग्राहक, प्रेरित कर्मचारी, प्रबुद्ध भागीदार, उत्साही समाज आणि निरोगी वातावरण निर्माण करणे.
                            <br/>व्यक्तींची योग्यता ओळखणे.
                            <br/>सर्वांसाठी मूल्य तयार करणे.
                            <br/>नवोपक्रम आणि शिक्षणाद्वारे उत्तम गोष्टी घडवून आणणे.
                            <br/>शॉर्ट मार्किंगमध्ये दररोज जगावर सकारात्मक आणि प्रभावी प्रभाव पडतो.</p>
                            {/* <p>सभासदांना बचत करण्याची सवय लावणे.</p>
                            <p>प्रत्येक कृतीत, दररोज ग्राहक.</p>
                            <p>एकत्र वाढणे, एकत्र यशस्वी होणे.</p>
                            <p>कार्यक्षम आणि पारदर्शक सेवांद्वारे सतत सुधारणा.</p>
                            <p>शाश्वतता कार्यक्रमांद्वारे लोकांचा विकास.</p>
                            <p>कृतीद्वारे विश्वास मिळवणे.</p>
                            <p>सर्वांना आधार आणि सहानुभूती प्रदान करणे.</p>
                            <p>सभासदांच्या नातेसंबंधावर विश्वास ठेवणे, नातेसंबंध निर्माण करा.</p>
                            <p>आनंदी ग्राहक, प्रेरित कर्मचारी, प्रबुद्ध भागीदार, उत्साही समाज आणि निरोगी वातावरण निर्माण करणे.</p>
                            <p>व्यक्तींची योग्यता ओळखणे.</p>
                            <p>सर्वांसाठी मूल्य तयार करणे.</p>
                            <p>नवोपक्रम आणि शिक्षणाद्वारे उत्तम गोष्टी घडवून आणणे.</p>
                            <p>शॉर्ट मार्किंगमध्ये दररोज जगावर सकारात्मक आणि प्रभावी प्रभाव पडतो.</p> */}
                        {/* <ul style={{ "lineHeight": 2 }}>
                            <li>सभासदांना बचत करण्याची सवय लावणे.</li>
                            <li>प्रत्येक कृतीत, दररोज ग्राहक.</li>
                            <li>एकत्र वाढणे, एकत्र यशस्वी होणे.</li>
                            <li>कार्यक्षम आणि पारदर्शक सेवांद्वारे सतत सुधारणा.</li>
                            <li>शाश्वतता कार्यक्रमांद्वारे लोकांचा विकास.</li>
                            <li>कृतीद्वारे विश्वास मिळवणे.</li>
                            <li>सर्वांना आधार आणि सहानुभूती प्रदान करणे.</li>
                            <li>सभासदांच्या नातेसंबंधावर विश्वास ठेवणे, नातेसंबंध निर्माण करा.</li>
                            <li>आनंदी ग्राहक, प्रेरित कर्मचारी, प्रबुद्ध भागीदार, उत्साही समाज आणि निरोगी वातावरण निर्माण करणे.</li>
                            <li>व्यक्तींची योग्यता ओळखणे.</li>
                            <li>सर्वांसाठी मूल्य तयार करणे.</li>
                            <li>नवोपक्रम आणि शिक्षणाद्वारे उत्तम गोष्टी घडवून आणणे.</li>
                            <li>शॉर्ट मार्किंगमध्ये दररोज जगावर सकारात्मक आणि प्रभावी प्रभाव पडतो.</li>
                        </ul> */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <img className="py-5" style={{ height: "100%", margin: "auto" }} src="Images/shrisatya-banner.jpeg"></img>
                    </div>
                </div>

            </div>
        </>
    );
};

export default About;

import React from "react";
import CarouselComp from "../Carousel/CarouselComp";
import Features from "../Features/Features";
import Testimonials from "../Testimonials/Testimonials";


const Hero = () => {
  return (
    <>
      <CarouselComp />
      <Features />
      {/* <Branch /> */}
      {/* <Testimonials /> */}
   
    </>
  );
};

export default Hero;
